import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FormField } from 'core/types/forms'
import React, { Fragment, useEffect, useState } from 'react'

interface CustomFormProps {
  fields: FormField[]
  submitText: string
  onSubmit: (data: { [key: string]: string | number | boolean }) => void
  onCancel: () => void
  initialValues?: { [key: string]: string | number | boolean | unknown }
  subTitles?: { [beforeFieldNumber: number]: string }
}

const CustomForm: React.FC<CustomFormProps> = ({
  fields,
  onSubmit,
  subTitles,
  submitText,
  onCancel,
  initialValues,
}) => {
  const [values, setValues] = useState<{ [key: string]: string | number | boolean }>({})
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  const getSelectValues = (selectField: FormField) => {
    if (typeof selectField.selectValues === 'function') {
      return selectField.selectValues(values)
    }

    return selectField.selectValues || []
  }

  useEffect(() => {
    const existingValues = Object.assign(
      {},
      ...fields.map(field => {
        if (field.type === 'select' && (!initialValues || !initialValues[field.name])) {
          return { [field.name]: getSelectValues(field)?.[0] }
        } else {
          return { [field.name]: initialValues ? initialValues[field.name] : '' }
        }
      }),
    )
    setValues(existingValues)
  }, [fields, initialValues])

  const validateFieldValue = (field: FormField, value: string | boolean | number) => {
    if (!value) return field?.required ? 'Field is required' : null

    if (field?.validation && field?.validation.validateValue && !field.validation.validateValue(value)) {
      return field.validation.errorText || 'Invalid value'
    }

    return null
  }

  const handleChange = (field: FormField, value: string | boolean | number) => {
    const errorText = validateFieldValue(field, value)
    if (errorText) {
      setErrors(prevErrors => ({ ...prevErrors, [field.name]: errorText }))
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [field.name]: '' }))
    }
    setValues(prevValues => ({ ...prevValues, [field.name]: value }))
  }


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const hasErrors = Object.values(errors).some(error => error != null && error !== '')
    const missingRequiredFields = fields.some(field => field.required && !values[field.name])
    if (hasErrors || missingRequiredFields) {
      const newErrors: { [key: string]: string } = {}
      fields.forEach(field => {
        const errorText = validateFieldValue(field, values[field.name])
        if (errorText) {
          newErrors[field.name] = errorText
        }
      })
      setErrors(newErrors)
      return
    }
    onSubmit(values)
  }

  return (
    <Box
      component='form'
      noValidate
      onSubmit={handleSubmit}
      height={{ xs: '100%', md: 'auto' }}
      width={{ xs: '100%', md: 'auto' }}
      overflow='hidden'
    >
      <Box flex={1} height='calc(100% - 64px)' sx={{ overflowY: 'auto' }}>
        <Grid container spacing={2}>
          {fields.map((field, index) => {
            return (
              <Fragment key={`${subTitles && subTitles[index] ? `subtitle-${index}-` : '-'}${field.name}`}>
                {subTitles && subTitles[index] && (
                  <Grid item xs={12} mt={2}>
                    <Typography variant='h4' fontWeight='bold'>
                      {subTitles[index]}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={field.fullWidth ? 12 : 6}>
                  {field.viewOnly ? (
                    <Box>
                      <Typography variant='h5' fontWeight='medium'>
                        {field.label}
                      </Typography>
                      <Typography fontWeight='light'>{values[field.name]}</Typography>
                    </Box>
                  ) : (
                    <Box>
                      <InputLabel
                        sx={{
                          color: 'black',
                          mb: 1,
                          display: field.showTitle ? 'block' : 'none',
                          fontWeight: 'medium',
                        }}
                      >
                        {field.label}
                      </InputLabel>
                      {field.type === 'text' && (
                        <TextField
                          {...(field.textFieldProps || {})}
                          InputProps={
                            field.button
                              ? {
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      {field.button(values[field.name])}
                                    </InputAdornment>
                                  ),
                                }
                              : undefined
                          }
                          required={field.required}
                          fullWidth
                          id={field.name}
                          name={field.name}
                          label={field.showTitle ? null : field.label}
                          value={values[field.name] || ''}
                          onChange={event => handleChange(field, event.target.value)}
                          error={!!errors[field.name]}
                          helperText={errors[field.name]}
                        />
                      )}
                      {field.type === 'select' && (
                        <FormControl error={!!errors[field.name]} fullWidth>
                          {!field.showTitle && <InputLabel id={field.name}>{field.label}</InputLabel>}
                          <Select
                            label={field.showTitle ? null : field.label}
                            name={field.name}
                            value={values[field.name as keyof typeof values] ?? getSelectValues(field)?.[0]}
                            onChange={event => handleChange(field, String(event.target.value))}
                          >
                            {getSelectValues(field)?.map(option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors[field.name] && <FormHelperText>{errors[field.name]}</FormHelperText>}
                        </FormControl>
                      )}
                      {field.type === 'check' && (
                        <FormControl error={!!errors[field.name]}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={Boolean(values[field.name]) || false}
                                onChange={event => handleChange(field, event.target.checked)}
                                name={field.name}
                              />
                            }
                            label={field.label}
                          />
                          {errors[field.name] && <FormHelperText>{errors[field.name]}</FormHelperText>}
                        </FormControl>
                      )}
                      {field.type == 'action' && field.actionElement && field.actionElement()}
                    </Box>
                  )}
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-around'
        alignItems='center'
        width='100%'
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '64px',
        }}
      >
        <Button type='button' color='secondary' onClick={onCancel}>
          Cancel
        </Button>
        <Button type='submit' variant='contained'>
          {submitText}
        </Button>
      </Box>
    </Box>
  )
}

export default CustomForm
