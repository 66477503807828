import { deleteDoc, doc, updateDoc } from '@firebase/firestore'
import { CarCrash, PhotoAlbumOutlined, Visibility, VisibilityOff } from '@mui/icons-material'
import ListIcon from '@mui/icons-material/List'
import TruckIcon from '@mui/icons-material/LocalShipping'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'
import { Box, Button, Grid, Switch, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid'
import { PageTitle } from 'components/PageTitle'
import DataGridFirestoreCRUD from 'components/table/DataGridFirestoreCRUD'
import { SelectionButtonT } from 'components/table/DataGridToolBar'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { downloadShareableInventoryCsv } from 'core/api/inventory/downloadShareableInventoryCSV'
import { downloadShareableInventoryPdf } from 'core/api/inventory/downloadShareableInventoryPdf'
import { generateOpticStockNumber } from 'core/api/inventory/generateStockNumber'
import { handleInventoryCsvUpload } from 'core/api/inventory/handleInventoryCsvUpload'
import removeFromWebsiteInventory from 'core/api/inventory/removeFromWebsiteInventory'
import { addToWebsiteInventory } from 'core/api/inventory/showHideOnWebsiteInventory'
import { db } from 'core/config/firebase'
import { formatDateTime, formatDistance, formatPrice } from 'core/utils/inventoryUtils'
import { useAddFirestoreDocument } from 'hooks/firebase/useAddFirestoreDocument'
import { useQueryFirestoreDocuments } from 'hooks/firebase/useQueryFirestoreDocuments'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { MainLayout } from 'layouts'
import React, { FC, useEffect, useState } from 'react'
import VehicleCard from './components/Cards/VehicleCard'
import ManagePhotosDialog from './components/Dialogs/ManagePhotosDialog'
import PhotoUploadDialog from './components/Dialogs/PhotoUploadDialog'
import SelectMainPhotoDialog from './components/Dialogs/SelectMainWebsitePhotoDialog'
import VehicleForm from './components/Forms/VehicleForm'
import VehicleSidebarFilter, { FilterState } from './components/VehicleSidebarFilter'

interface InventoryManagementProps {
  inventoryCollectionName: string
}

const InventoryManagement: FC<InventoryManagementProps> = ({ inventoryCollectionName }) => {
  const [openVehicleForm, setOpenVehicleForm] = useState(false)
  const [openPhotoUploadDialog, setOpenPhotoUploadDialog] = useState(false)
  const [openManagePhotosDialog, setOpenManagePhotosDialog] = useState(false)
  const [openSelectMainPhotoDialog, setOpenSelectMainPhotoDialog] = useState(false)
  const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[]>([])
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)
  const [view, setView] = React.useState<'card' | 'list'>('list')
  const { showSnackbar } = useSnackbar()

  const { userInfo } = useAuth()
  const uType = userInfo?.user_type

  const isMobile = useIsMobile()
  const isOpticInventory = inventoryCollectionName === 'master_inventory'
  const userGuidelines = [
    'Upload CSV to add multiple trucks at once.',
    'You can edit the inventory details by double clicking on the row. Select multiple rows to delete them.',
    "Use the 'Send Photo Upload' button to send a photo upload to a representative.",
    "Use the 'Associate Finance Application' button to associate a finance application with an existing inventory item.",
  ]

  const handleManagePhotosClick = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle as Vehicle)
    setOpenManagePhotosDialog(true)
  }

  const handleShowHideOnWebsiteClick = async (vehicle: Vehicle) => {
    if (vehicle.show_on_website !== true) {
      setOpenSelectMainPhotoDialog(true)
    }

    const inventoryDocRef = doc(db, inventoryCollectionName, vehicle.id)

    if (vehicle.show_on_website) {
      await removeFromWebsiteInventory(vehicle.id)
    } else {
      await addToWebsiteInventory(vehicle.id)
    }
    await updateDoc(inventoryDocRef, { show_on_website: !vehicle.show_on_website })
  }

  const columns = getInventoryColumns({
    isAdmin: uType === 'admin',
    onManagePhotosClick: handleManagePhotosClick,
    onShowHideOnWebsiteClick: isOpticInventory
      ? handleShowHideOnWebsiteClick
      : () => showSnackbar('Only available for Optic Inventory', 'error'),
  })

  const handleShareInventoryListClick = (ids: string[] | GridRowId[]) => {
    const masterInventoryIds = ids.map(id => id.toString())
    downloadShareableInventoryPdf(inventoryCollectionName, masterInventoryIds)
  }

  const handleDownloadInventoryCSVClick = (ids: string[] | GridRowId[]) => {
    const masterInventoryIds = ids.map(id => id.toString())

    if (uType) {
      downloadShareableInventoryCsv(inventoryCollectionName, masterInventoryIds, uType)
    }
  }

  const customToolbarSelectionButtons: SelectionButtonT[] = [
    {
      icon: <CarCrash />,
      label: 'Download PDF',
      onClick: handleShareInventoryListClick,
    },
    {
      icon: <CarCrash />,
      label: 'Download CSV',
      onClick: handleDownloadInventoryCSVClick,
    },
  ]

  const {
    data: vehicles,
    isLoading,
    isSuccess: loadedVehicles,
    refetch: refetchVehicles,
  } = useQueryFirestoreDocuments({
    inventoryCollectionName,
    useQueryOptions: {
      subscribe: true,
    },
  })

  useEffect(() => {
    refetchVehicles
  }, [loadedVehicles, inventoryCollectionName])

  useEffect(() => {
    if (vehicles) {
      // can be deleted when no duplicate vins loaded via csv
      const uniqueVehicles = (vehicles as Vehicle[]).filter(
        (vehicle, index, self) => index === self.findIndex(v => v.vin === vehicle.vin),
      )
      setFilteredVehicles(uniqueVehicles)
    }
  }, [loadedVehicles])

  const { mutate: addFirebaseDocument } = useAddFirestoreDocument({ collectionName: inventoryCollectionName })

  // TODO: Replace with react-query
  const handleDeleteVehicle = async (vehicleId: string) => {
    const inventoryRef = doc(db, inventoryCollectionName, vehicleId)
    await deleteDoc(inventoryRef)
  }

  const handleAddVehicle = async (data: { [key: string]: string | number | boolean }) => {
    const newValues = Object.fromEntries(Object.entries(data).filter(([, value]) => value !== undefined))
    newValues.date_added = new Date().toISOString()
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      const opticListPrice = Number(newValues.optic_list_price) || 0 // Default to 0 if not valid
      const sellerAskingPrice = Number(newValues.seller_asking_price) || 0 // Default to 0 if not valid

      // Calculate profit: sale price - buy price and convert to string
      newValues.profit = (sellerAskingPrice - opticListPrice).toString()
    } else {
      newValues.profit = '0' // Set profit to '0' if prices are not provided
    }
    addFirebaseDocument(newValues)
    setOpenVehicleForm(false)
  }

  const handleEditVehicle = async (data: { [key: string]: string | number | boolean }) => {
    if (!selectedVehicle) return
    const sanitizedData = { ...data }
    if (typeof sanitizedData.miles === 'string') {
      sanitizedData.miles = sanitizedData.miles.replace(/,/g, '')
    }
    if (typeof sanitizedData.optic_list_price === 'string') {
      sanitizedData.optic_list_price = sanitizedData.optic_list_price.replace(/,/g, '')
    }
    if (typeof sanitizedData.seller_asking_price === 'string') {
      sanitizedData.seller_asking_price = sanitizedData.seller_asking_price.replace(/,/g, '')
    }
    const newValues = Object.fromEntries(Object.entries(sanitizedData).filter(([, value]) => value !== undefined))
    newValues.date_added = new Date().toISOString()
    if (newValues.optic_list_price && newValues.seller_asking_price) {
      const opticListPrice = Number(newValues.optic_list_price) || 0 // Default to 0 if not valid
      const sellerAskingPrice = Number(newValues.seller_asking_price) || 0 // Default to 0 if not valid

      // Calculate profit: sale price - buy price and convert to string
      newValues.profit = (sellerAskingPrice - opticListPrice).toString()
    } else {
      newValues.profit = '0' // Set profit to '0' if prices are not provided
    }

    try {
      const docRef = doc(db, inventoryCollectionName, selectedVehicle.id)
      await updateDoc(docRef, newValues)
      setOpenVehicleForm(false)
    } catch (error) {
      showSnackbar('Error updating vehicle', 'error')
      console.error('Error updating vehicle:', error)
    }
  }

  const handlePhotoUploadClick = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setOpenPhotoUploadDialog(true)
  }

  const handleClickVehicle = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setOpenVehicleForm(true)
  }

  const handleDownloadCSVTemplate = () => {
    window.open('/inventory_template.csv')
  }

  const handleFilterChange = (filters: FilterState) => {
    if (!vehicles) return

    const uniqueVehicles = (vehicles as Vehicle[]).filter(
      (vehicle, index, self) => index === self.findIndex(v => v.vin === vehicle.vin),
    )

    const filtered = uniqueVehicles.filter(vehicle => {
      return (
        (!filters.vin || (vehicle.vin || '').toLowerCase().includes(filters.vin.toLowerCase())) &&
        (!filters.type || vehicle.type === filters.type) &&
        (!filters.size || vehicle.size === filters.size) &&
        (!filters.fuel || vehicle.fuel === filters.fuel) &&
        Number(vehicle.miles) >= filters.miles[0] &&
        Number(vehicle.miles) <= filters.miles[1] &&
        Number(vehicle.optic_list_price) >= filters.listPrice[0] &&
        Number(vehicle.optic_list_price) <= filters.listPrice[1] &&
        Number(vehicle.seller_asking_price) >= filters.askingPrice[0] &&
        Number(vehicle.seller_asking_price) <= filters.askingPrice[1] &&
        (!filters.location || (vehicle.location || '').toLowerCase().includes(filters.location.toLowerCase())) &&
        (!filters.isAvailable || vehicle.status === 'Available')
      )
    })
    setFilteredVehicles(filtered as Vehicle[])
  }

  const handleFilterClear = () => {
    setFilteredVehicles((vehicles as Vehicle[]) ?? [])
  }

  return (
    <MainLayout>
      <PageTitle
        title={`${isOpticInventory ? 'Optic' : 'Shield'} Inventory Management`}
        subtitle='Manage the inventory of trucks.'
        bulletPoints={userGuidelines}
      />
      {!isMobile && (
        <Box mb={2}>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={(_event, newView) => {
              if (newView) {
                setView(newView)
              }
            }}
            aria-label='text alignment'
          >
            <ToggleButton value='list' aria-label='list view'>
              <ListIcon />
            </ToggleButton>
            <ToggleButton value='card' aria-label='card view'>
              <SpaceDashboardIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      <Box
        maxWidth={isMobile ? '100%' : '400px'}
        display='flex'
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent='space-between'
        alignItems='center'
        sx={{ marginBottom: 2, gap: isMobile ? 2 : 1 }}
      >
        {!isMobile && (
          <>
            <Button
              variant='contained'
              component='label'
              fullWidth={isMobile}
              sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
              onClick={() => handleDownloadCSVTemplate()}
            >
              Download CSV Template
            </Button>

            <Button
              variant='contained'
              component='label'
              fullWidth={isMobile}
              sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
            >
              Upload CSV
              <input
                type='file'
                hidden
                onChange={event => handleInventoryCsvUpload(event, inventoryCollectionName)}
              />
            </Button>
          </>
        )}
        <Button
          aria-hidden={false}
          variant='contained'
          startIcon={<TruckIcon />}
          onClick={() => {
            setSelectedVehicle(null)
            setOpenVehicleForm(true)
          }}
          fullWidth={isMobile}
          sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
        >
          Add Truck
        </Button>
      </Box>

      {isLoading ? (
        <div>Loading Vehicles...</div>
      ) : isMobile || view === 'card' ? (
        <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2} p={2}>
         <VehicleSidebarFilter
            onFilterChange={handleFilterChange}
            onFilterClear={handleFilterClear}
            isAdmin={uType === 'admin'}
          /> 
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: 'center',
              flexWrap: 'wrap',
              overflowX: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
            }}
          >
            {filteredVehicles &&
              filteredVehicles.map(vehicle => (
                <Grid item key={vehicle.id} xs={12} sm={6} md={4} lg={4}>
                  <VehicleCard
                    collectionName={inventoryCollectionName}
                    vehicle={vehicle}
                    onClick={() => handleClickVehicle(vehicle)}
                    onDeleteClick={() => handleDeleteVehicle(vehicle.id)}
                    onManagePhotosClick={() => handleManagePhotosClick(vehicle)}
                    onPhotoUploadClick={() => handlePhotoUploadClick(vehicle)}
                    onShowHideOnWebsiteClick={() => handleShowHideOnWebsiteClick(vehicle)}
                    isAdmin={uType === 'admin'}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      ) : (
        <DataGridFirestoreCRUD
          columns={columns}
          hiddenColumns={['engine', 'GVW', 'boxSize', 'transmission']}
          collectionName={inventoryCollectionName}
          editable={false}
          deleteable={true}
          create={true}
          search={true}
          multiDeleteable={true}
          onDeleteClick={handleDeleteVehicle}
          onDoubleClick={id => handleClickVehicle(vehicles?.find(vehicle => vehicle.id === id) as Vehicle)}
          customToolbarSelectionButtons={customToolbarSelectionButtons}
          viewOnlyMode={true}
          canShowDocViewer={userInfo?.email.includes('freetech.co')}
        />
      )}
      <VehicleForm
        open={openVehicleForm}
        vehicle={selectedVehicle}
        onClose={() => setOpenVehicleForm(false)}
        onSubmit={selectedVehicle ? handleEditVehicle : handleAddVehicle}
        generateStockNumber={() =>
          generateOpticStockNumber(
            (vehicles as Vehicle[])?.map(vehicle => vehicle.stock_number) || [],
            userInfo?.first_name || '',
            userInfo?.last_name || '',
          )
        }
      />
      {selectedVehicle && (
        <>
          <PhotoUploadDialog
            open={openPhotoUploadDialog}
            onClose={() => setOpenPhotoUploadDialog(false)}
            vehicle={selectedVehicle}
          />
          <ManagePhotosDialog
            collectionName={inventoryCollectionName}
            open={openManagePhotosDialog}
            onClose={() => setOpenManagePhotosDialog(false)}
            vehicle={selectedVehicle}
          />
          <SelectMainPhotoDialog
            collectionName={inventoryCollectionName}
            open={openSelectMainPhotoDialog}
            onClose={() => setOpenSelectMainPhotoDialog(false)}
            vehicle={selectedVehicle}
          />
        </>
      )}
    </MainLayout>
  )
}
interface InventoryColumnsProps {
  isAdmin: boolean
  onManagePhotosClick: (vehicle: Vehicle) => void
  onShowHideOnWebsiteClick: (vehicle: Vehicle) => void
}

const getInventoryColumns = ({
  isAdmin,
  onManagePhotosClick,
  onShowHideOnWebsiteClick,
}: InventoryColumnsProps): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: 'date_added',
      headerName: 'Timestamp',
      valueFormatter: formatDateTime,
    },
    { field: 'vin', headerName: 'VIN', editable: true },
    { field: 'stock_number', headerName: 'Stock Number', editable: true },
    { field: 'make', headerName: 'Make', editable: true },
    { field: 'model', headerName: 'Model', editable: true },
    { field: 'type', headerName: 'Type', editable: true },
    { field: 'size', headerName: 'Size', editable: true },
    { field: 'fuel', headerName: 'Fuel', editable: true },
    { field: 'miles', headerName: 'Mileage', editable: true, valueFormatter: formatDistance },
    { field: 'year', headerName: 'Year', editable: true },
    { field: 'condition', headerName: 'Condition', editable: true },
    { field: 'location', headerName: 'Location', editable: true },
    { field: 'who', headerName: 'Who', editable: true },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: vehicleStatusOptions,
    },
  ]
  if (isAdmin) {
    columns.push({
      field: 'optic_list_price',
      headerName: 'Buy Price',
      valueFormatter: formatPrice,
    })
  }

  columns.push({
    field: 'seller_asking_price',
    headerName: 'Sale Price',
    valueFormatter: formatPrice,
  })

  if (isAdmin) {
    columns.push({
      field: 'profit',
      headerName: 'Profit',
      valueFormatter: formatPrice
    })
  }

  columns.push({
    field: 'url',
    headerName: 'URL',
    minWidth: 80,
    renderCell: params =>
      params.value &&
      params.value.length > 0 && (
        <Button
          variant='text'
          onClick={() => window.open(params.value, '_blank', 'noopener,noreferrer')}
          sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
        >
          View
        </Button>
      ),
  })

  columns.push({
    field: 'Photos',
    type: 'actions',
    headerName: 'Photos',
    editable: false,
    flex: 1,
    getActions: params => [
      <GridActionsCellItem
        key={params.id}
        icon={<PhotoAlbumOutlined />}
        label='Send Reminder Email'
        onClick={() => onManagePhotosClick(params.row)}
        color='inherit'
        disabled={params.row.email_verified}
      />,
    ],
  })
  columns.push({
    field: 'show_hide',
    type: 'actions',
    headerName: 'Show Hide',
    editable: false,
    flex: 1,
    getActions: params => [
      <Switch
        key={params.id}
        checked={params.row.show_on_website}
        icon={<VisibilityOff />}
        checkedIcon={<Visibility />}
        onChange={() => onShowHideOnWebsiteClick(params.row)}
        color={params.row.show_on_website ? 'success' : 'error'}
      />,
    ],
  })
  columns.push({
    field: 'Photo Upload',
    type: 'actions',
    headerName: 'Photo Upload',
    editable: false,
    flex: 1,
    getActions: params => [
      <Switch
        key={params.id}
        checked={params.row.show_on_website}
        icon={<VisibilityOff />}
        checkedIcon={<Visibility />}
        onChange={() => onShowHideOnWebsiteClick(params.row)}
        color={params.row.show_on_website ? 'success' : 'error'}
      />,
    ],
  })
  columns.push({ field: 'Sold Date', headerName: 'Sold Date' })

  return columns
}

// TODO: find logical place for vehicle options

export const vehicleStatusOptions = ['Pending', 'Available', 'Pending Sale', 'Sold']

export const vehicleTypeOptions = ['Step van', 'Cargo van', 'Cutaway', 'Box truck']

export const vehicleFuelOptions = ['Gas', 'Diesel']

export const getVehicleSizeOptions = (vehicleType: string) => {
  switch (vehicleType) {
    case 'Step van':
      return ['', 'p 500', 'p 700', 'p 900', 'p 1000', 'p 1100', 'p 1200', 'Unknown']
    case 'Cargo van':
      return ['Unknown']
    case 'Cutaway':
      return ['', '10ft', '12ft', '14ft', '16ft', '18ft', 'Unknown']
    case 'Box truck':
      return ['', '12ft', '14ft', '16ft', '18ft', '20ft', '22ft', '24ft', '26ft', 'Unknown']
    default:
      return ['']
  }
}

export default InventoryManagement
