import {
  AttachMoney,
  CalendarToday,
  CarCrash,
  Delete,
  DirectionsCar,
  LocalGasStation,
  LocationOn,
  PhotoAlbumOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import {
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { getPhotosForInventoryObject } from 'core/api/inventory/getPhotosForInventoryObject'
import { formatDateTime, formatDistance, formatPrice } from 'core/utils/inventoryUtils'
import React from 'react'
import { useQuery } from 'react-query'

interface VehicleCardProps {
  collectionName: string
  vehicle: Vehicle
  onClick: () => void
  onDeleteClick: () => void
  onManagePhotosClick: () => void
  onShowHideOnWebsiteClick: () => void
  onPhotoUploadClick: () => void,
  isAdmin?: boolean
}

const VehicleCard: React.FC<VehicleCardProps> = ({
  collectionName,
  vehicle,
  onClick,
  onDeleteClick,
  onManagePhotosClick,
  onShowHideOnWebsiteClick,
  onPhotoUploadClick,
  isAdmin
}) => {
  const { userInfo } = useAuth()

  const { data: photos, isLoading: isLoadingPhoto } = useQuery(['photos', vehicle.id], async () =>
    getPhotosForInventoryObject(collectionName, vehicle.id),
  )

  return (
    <Card sx={{ minWidth: 275, position: 'relative', '&:hover': { boxShadow: 6 } }} onClick={onClick}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: '2px solid',
          borderColor: 'primary.main',
          opacity: 0,
          transition: 'opacity 0.3s',
          '&:hover': { opacity: 1 },
        }}
      />
      <CardHeader
        title={
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6'>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Typography>
            {vehicle.status && (
              <Badge
                color={vehicle.status === 'Available' ? 'success' : 'secondary'}
                badgeContent={vehicle.status}
                sx={{
                  '& .MuiBadge-badge': {
                    marginRight: 4,
                    color: 'white',
                    bgcolor: vehicle.status === 'Available' ? '#2e7d32' : '',
                  },
                }}
              />
            )}
          </Box>
        }
        subheader={
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='body2'>Stock Number: {vehicle.stock_number}</Typography>
          </Box>
        }
      />
      {isLoadingPhoto ? (
        <Box display='flex' justifyContent='center' alignItems='center' height={200}>
          <CircularProgress sx={{ mt: 2 }} />
        </Box>
      ) : photos && photos.length > 0 ? (
        <CardMedia
          component='img'
          srcSet={`${photos[0].url}?w=82&h=82&fit=crop&auto=format&dpr=2 2x`}
          src={`${photos[0].url}?w=82&h=82&fit=crop&auto=format`}
          alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          loading='lazy'
          height={200}
        />
      ) : (
        <Box display='flex' justifyContent='center' alignItems='center' height={200}>
          <Typography>No Photo Available</Typography>
        </Box>
      )}
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
              <DirectionsCar fontSize='small' /> Vehicle Info
            </Typography>
            <Typography variant='body2'>
              <strong>VIN:</strong> {vehicle.vin}
            </Typography>
            <Typography variant='body2'>
              <strong>Stock #:</strong> {vehicle.stock_number}
            </Typography>
            <Typography variant='body2'>
              <strong>Type:</strong> {vehicle.type}
            </Typography>
            <Typography variant='body2'>
              <strong>Size:</strong> {vehicle.size}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
              <LocalGasStation fontSize='small' /> Performance
            </Typography>
            <Typography variant='body2'>
              <strong>Fuel:</strong> {vehicle.fuel}
            </Typography>
            <Typography variant='body2'>
              <strong>Miles:</strong> {formatDistance(vehicle.miles)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
              <LocationOn fontSize='small' /> Location & Contact
            </Typography>
            <Typography variant='body2'>
              <strong>Location:</strong> {vehicle.location}
            </Typography>
            <Typography variant='body2'>
              <strong>Contact:</strong> {vehicle.who}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
              <AttachMoney fontSize='small' /> Pricing
            </Typography>
            {isAdmin && (
              <Typography variant='body2'>
                <strong>Buy Price:</strong> {formatPrice(vehicle.optic_list_price)}
              </Typography>
            )}
            <Typography variant='body2'>
              <strong>Sell Price:</strong> {formatPrice(vehicle.seller_asking_price)}
            </Typography>
            {isAdmin && (
              <Typography variant='body2'>
                <strong>Potential Profit:</strong> {formatPrice(vehicle.profit)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' alignItems='center' gap={2}>
              <Typography variant='body2' display='flex' alignItems='center' gap={1}>
                <CalendarToday fontSize='small' /> <strong>Added:</strong> {formatDateTime(vehicle.date_added)}
              </Typography>
              {vehicle.sold_date && (
                <Typography variant='body2' display='flex' alignItems='center' gap={1}>
                  <CalendarToday fontSize='small' /> <strong>Sold:</strong> {vehicle.sold_date}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
          <Box display='flex' alignItems='center'>
            <IconButton
              aria-label='Manage Photos'
              onClick={event => {
                event.stopPropagation()
                onManagePhotosClick()
              }}
              disabled={!userInfo?.email_verified}
            >
              <PhotoAlbumOutlined />
            </IconButton>
            <Switch
              checked={vehicle.show_on_website}
              icon={<VisibilityOff />}
              checkedIcon={<Visibility />}
              onClick={event => {
                event.stopPropagation()
              }}
              onChange={onShowHideOnWebsiteClick}
              color={vehicle.show_on_website ? 'success' : 'error'}
            />
            <IconButton
              aria-label='Upload Photos'
              onClick={event => {
                event.stopPropagation()
                onPhotoUploadClick()
              }}
              disabled={!userInfo?.email_verified}
            >
              <CarCrash />
            </IconButton>
          </Box>
          <IconButton
            aria-label='Delete Vehicle'
            onClick={event => {
              event.stopPropagation()
              console.log('click delete')
              onDeleteClick()
            }}
          >
            <Delete />
          </IconButton>
        </Box>
      </CardActions>
    </Card>
  )
}

export default VehicleCard
