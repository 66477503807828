import { Box, Button, Dialog, DialogContent, DialogTitle, InputAdornment, Typography } from '@mui/material'
import CustomForm from 'components/CustomForm'
import { useAuth } from 'contexts/AuthContext'
import { FormField } from 'core/types/forms'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useEffect, useState } from 'react'
import truckLogo from '../../../../../../assets/Icon-OpticTruckWorks-02.png'
import { getVehicleSizeOptions, vehicleFuelOptions, vehicleStatusOptions, vehicleTypeOptions } from '../../InventoryManagement'

interface VehicleFormProps {
  open: boolean
  vehicle: Vehicle | null
  onClose: () => void
  onSubmit: (data: { [key: string]: string | number | boolean }) => void
  generateStockNumber: () => string
}

function isValidURL(url: string) {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

// TODO: add scanner vin in endendorment

const VehicleForm: React.FC<VehicleFormProps> = ({ open, onClose, onSubmit, vehicle, generateStockNumber }) => {
  const [vehicleValues, setVehicleValues] = useState({})
  const { userInfo } = useAuth()
  const isMobile = useIsMobile()
  const isNew = vehicle === null
  const isAdmin = userInfo?.user_type === 'admin'
  const fields = getInventoryFormFields(isAdmin)

  const subTitles: { [beforFieldNumber: number]: string } = { 3: 'Truck Information', 12: 'Contact Information' }
  if (isAdmin) {
    subTitles[14] = 'Finance Information'
  }

  useEffect(() => {
    if (open && isNew && userInfo) {
      setVehicleValues({
        stock_number: generateStockNumber(),
      })
    }
  }, [open, isNew, userInfo])

  useEffect(() => {
    if (!isNew && vehicle) {
      setVehicleValues({
        ...vehicle,
      })
    }
  }, [vehicle])

  if (!vehicle && isNew && !userInfo) {
    return null
  }

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle p={0} justifyContent='center' alignContent='center' display='flex'>
        <Typography variant='h1'> {isNew ? 'Add' : 'Edit'} Truck</Typography>
        <Box component='img' src={truckLogo} height={48} width={48} alt='' />
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', height: '100%' }}>
        <CustomForm
          fields={fields}
          initialValues={vehicleValues}
          subTitles={subTitles}
          onSubmit={onSubmit}
          submitText='Submit'
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  )
}

const getInventoryFormFields = (isAdmin: boolean) => {
  const fields: FormField[] = [
    {
      name: 'vin',
      type: 'text',
      label: 'VIN',
      validation: {
        validateValue: value => typeof value === 'string' && value.length === 17,
        errorText: 'VIN must be exactly 17 characters long',
      },
      textFieldProps: {
        InputProps: {
          endAdornment: <InputAdornment position='end'></InputAdornment>,
        },
      },
      fullWidth: true,
      showTitle: true,
      required: true,
    },
    { name: 'stock_number', type: 'text', label: 'Stock Number', required: true, viewOnly: true },
    {
      name: 'status',
      type: 'select',
      label: 'Status',
      required: true,
      selectValues: vehicleStatusOptions,
    },
    { name: 'make', type: 'text', label: 'Make', required: true },
    { name: 'model', type: 'text', label: 'Model', required: true },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      selectValues: ['', ...vehicleTypeOptions, 'Unknown'],
      required: true,
    },
    {
      name: 'size',
      type: 'select',
      label: 'Size',
      selectValues: values => getVehicleSizeOptions(values.type as string),
      required: true,
    },
    { name: 'fuel', type: 'select', label: 'Fuel', selectValues: vehicleFuelOptions, required: true },
    {
      name: 'miles',
      type: 'text',
      label: 'Mileage',
      required: true,
      validation: {
        validateValue: (value: string | number | boolean) => {
          const sanitizedValue = typeof value === 'string' ? value.replace(/,/g, '') : String(value)
          const num = Number(sanitizedValue)
          return !isNaN(num) && num >= 0
        },
        errorText: 'Mileage must be a positive number',
      },
      textFieldProps: {
        type: 'number',
      },
    },
    {
      name: 'year',
      type: 'text',
      label: 'Year',
      required: true,
      validation: {
        validateValue: value => {
          const num = Number(value)
          if (isNaN(num)) return false

          return num >= 2000 && num <= new Date().getFullYear()
        },
        errorText: 'Year must be between 2000 and current year',
      },
    },
    { name: 'location', type: 'text', label: 'Location', required: true },
    { name: 'condition', type: 'select', label: 'Condition', selectValues: ['', 'Used', 'New'], required: true },
    {
      name: 'url',
      type: 'text',
      label: 'Web Link',
      validation: {
        validateValue: value => typeof value === 'string' && isValidURL(value),
        errorText: 'URL is not valid',
      },
      button: value => <Button onClick={() => window.open(String(value), '_blank')}>View</Button>,
      fullWidth: true,
    },

    {
      name: 'who',
      type: 'text',
      label: 'Who',
      required: false,
      showTitle: true,
    },
  ]

  if (isAdmin) {
    const validationPrice = {
      validateValue: (value: string | number | boolean) => {
        const sanitizedValue = typeof value === 'string' ? value.replace(/,/g, '') : String(value)
        const num = Number(sanitizedValue)
        return !isNaN(num) && num >= 0
      },
      errorText: 'Price must be a positive number',
    }
    const financeFields: FormField[] = [
      {
        name: 'optic_list_price',
        type: 'text',
        label: 'Buy Price',
        validation: validationPrice,
        textFieldProps: {
          InputProps: { startAdornment: <InputAdornment position='start'>$</InputAdornment> },
          type: 'number',
        },
      },
      {
        name: 'seller_asking_price',
        type: 'text',
        label: 'Sale Price',
        validation: validationPrice,
        textFieldProps: {
          InputProps: { startAdornment: <InputAdornment position='start'>$</InputAdornment> },
          type: 'number',
        },
      },
      {
        name: 'sold_date',
        type: 'text',
        label: 'Sold Date',
      },
    ]
    fields.push(...financeFields)
  }

  return fields
}

export default VehicleForm
